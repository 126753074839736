import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { CONFIG } from '@constants';
import { IMenuItem } from '@features/menu/menuSlice';
import { useAppSelector } from '@hooks';

interface IUseModuleStyles {
  menuItem?: IMenuItem;
  isSidebarOpen?: boolean;
  groupWithActiveItem?: string;
}

export const useModuleStyles = ({
  menuItem,
  isSidebarOpen,
  groupWithActiveItem,
}: IUseModuleStyles = {}) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const appConfig = useAppSelector((state) => state.appConfig.config);
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const isDarkMode = appConfig.mode === 'dark';
  const isIframe = window.self !== window.top;

  const isPopupIframe =
    isIframe &&
    ['give-bonus', 'activate-code'].some((path) => pathname.includes(path));

  const buttonDarkColor =
    theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';

  const buttonLightColor =
    theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  const listItemTextColor =
    theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';

  const iconSelectedColor =
    theme.palette.mode === 'dark' && isSidebarOpen
      ? theme.palette.text.primary
      : theme.palette.primary.main;

  const layoutContentWrapperStyles = {
    flexGrow: 1,
    width: 'calc(100% - 260px)',
    ...(!isIframe ? { p: { xs: 2, sm: 3 } } : {}),
    ...(isIframe && isPopupIframe ? {} : { padding: '1.5rem 0' }),
  };

  const outletContainerStyles: React.CSSProperties = {
    ...(appConfig.container && !isIframe ? { px: { xs: 0, sm: 2 } } : {}),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    ...(matchDownSM ? { marginBottom: '70px' } : {}),
    ...(isIframe ? !isPopupIframe && { paddingLeft: '0 !important' } : {}),
    ...(!isIframe ? { minHeight: 'calc(100vh - 108px)' } : {}),
    ...(isIframe && isPopupIframe
      ? { margin: '0 !important', padding: '0 !important' }
      : {}),
  };

  const layoutWrapperStyles = {
    width: '100%',
    display: 'flex',
    backgroundColor: isIframe ? 'none' : theme.palette.grey.A900,
  };

  const themeButtonStyles = {
    marginLeft: { xs: 0, lg: -2 },
    bgcolor: isDarkMode ? buttonDarkColor : buttonLightColor,
    color: isDarkMode
      ? theme.palette.primary.main
      : theme.palette.secondary.main,
  };

  const mobileHeaderContainerStyles = {
    gap: '2rem',
    alignItems: 'center',
    flexDirection: 'row',
    alignContent: 'center',
  };

  const appBarDefaultWidth = isSidebarOpen
    ? 'calc(100% - 260px)'
    : { xs: '100%', lg: 'calc(100% - 60px)' };

  const appBarStyles = {
    zIndex: 1200,
    width: isIframe ? '100%' : appBarDefaultWidth,
    borderBottom: `1px solid ${theme.palette.divider}`,
  };

  const headerLeftColumnStyles = {
    gap: 4,
    alignItems: 'center',
    flexDirection: 'row',
  };

  const sidebarSwitcherStyles = {
    color: 'text.primary',
    marginLeft: { xs: 0, lg: 0 },
    bgcolor: isSidebarOpen ? buttonDarkColor : buttonLightColor,
  };

  const drawerStyles = {
    display: { lg: 'none', xs: 'block' },
    '& .MuiDrawer-paper': {
      boxShadow: 'inherit',
      backgroundImage: 'none',
      boxSizing: 'border-box',
      width: CONFIG.SIDEBAR_WIDTH,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  };

  const sidebarHeaderStyles = {
    display: 'flex',
    width: '2.75rem',
    height: '3.75rem',
    paddingTop: '8px',
    alignItems: 'center',
    justifyContent: isSidebarOpen ? 'flex-start' : 'center',
    paddingLeft: theme.spacing(isSidebarOpen ? 2.125 : 1.875),
  };

  const sidebarHeaderLogoStyles = {
    width: '2.75rem',
    height: '3.75rem',
    maxHeight: '3.75rem',
    objectFit: 'contain',
  };

  const sidebarHeaderContainerStyles = {
    maxWidth: '12.5rem',
    alignItems: 'center',
    flexDirection: 'row',
  };

  const navigationStyles = {
    display: 'block',
    paddingTop: '1rem',
    '& > ul:first-of-type': { marginTop: 0 },
  };

  const navGroupListStyles = {
    zIndex: 0,
    paddingBlock: 0,
    marginBottom: 0,
  };

  const listItemStyles = {
    paddingLeft: isSidebarOpen ? '1.875rem' : '0.75rem',
    ...(isSidebarOpen
      ? {
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
          },
          '&.Mui-selected': {
            color: iconSelectedColor,
            backgroundColor: 'primary.lighter',
            borderRight: `2px solid ${theme.palette.primary.main}`,
            '&:hover': {
              color: iconSelectedColor,
              backgroundColor: 'primary.lighter',
            },
          },
        }
      : {}),
    ...(!isSidebarOpen
      ? {
          '&:hover': {
            backgroundColor: 'primary.lighter',
          },
          backgroundColor:
            groupWithActiveItem === menuItem?.id
              ? 'primary.lighter'
              : 'transparent',
          '&.Mui-selected': {
            backgroundColor: 'primary.lighter',
            '&:hover': {
              backgroundColor: 'primary.lighter',
            },
          },
        }
      : {}),
  };

  const listItemIconStyles = {
    minWidth: 28,
    color: listItemTextColor,
    ...(!isSidebarOpen
      ? {
          width: 36,
          height: 36,
          borderRadius: 1.5,
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {}),
  };

  const navCollapsePaperStyles = {
    marginTop: 1.5,
    overflow: 'hidden',
    backgroundImage: 'none',
    boxShadow: theme.customShadows.z1,
    border: `1px solid ${theme.palette.divider}`,
  };

  const navCollapseArrowUpStyles = {
    marginLeft: 1,
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
  };

  const navCollapseArrowDownStyles = {
    marginLeft: 1,
    fontSize: '0.75rem',
  };

  const listItemButtonStyles = {
    zIndex: 1201,
    paddingBlock: 1,
    ...(isSidebarOpen
      ? {
          '&:hover': {
            backgroundColor: 'primary.lighter',
          },
          '&.Mui-selected': {
            color: 'primary.main',
            backgroundColor: 'primary.lighter',
            borderRight: `2px solid ${theme.palette.primary.main}`,

            '&:hover': {
              color: 'primary.main',
              backgroundColor: 'primary.lighter',
            },
          },
        }
      : {}),
    ...(!isSidebarOpen
      ? {
          '&:hover': {
            backgroundColor: 'primary.lighter',
          },
          '&.Mui-selected': {
            backgroundColor: 'primary.lighter',
            '&:hover': {
              backgroundColor: 'primary.lighter',
            },
          },
        }
      : {}),
  };

  return {
    appBarStyles,
    drawerStyles,
    listItemStyles,
    navigationStyles,
    themeButtonStyles,
    listItemTextColor,
    navGroupListStyles,
    listItemIconStyles,
    layoutWrapperStyles,
    sidebarHeaderStyles,
    listItemButtonStyles,
    outletContainerStyles,
    sidebarSwitcherStyles,
    navCollapsePaperStyles,
    headerLeftColumnStyles,
    sidebarHeaderLogoStyles,
    navCollapseArrowUpStyles,
    layoutContentWrapperStyles,
    navCollapseArrowDownStyles,
    mobileHeaderContainerStyles,
    sidebarHeaderContainerStyles,
  };
};
